/*!
 * Layout
 */


//==================================================
//                     RESET
//==================================================

*:focus
	outline: none !important

//==================================================
//                     IMPORTS
//==================================================

@import "variables"
@import "mixins"

//==================================================
//                  NAVIGATION
//==================================================

.navbar
	background-color: transparent
	border: none
	.navbar-header
		> .container
			background-color: white
			box-shadow: 0 -3px 3px rgba(black, .1)
		.language, .btn-facebook
			float: right
	.language
		padding: 30px 20px
		a
			display: inline-block
			padding: 0 10px 2px
			color: $gray-darker
			&.active
				font-weight: 700
			+ a
				border-left: 1px solid $gray-darker
				margin-left: -2px
	.btn-facebook
		width: 100%
		margin: 0 auto 15px
		@media screen and (min-width: $screen-sm-min)
			width: auto
			margin: 22px 20px
	.navbar-brand
		padding: 20px 0
		position: absolute
		width: auto
		height: 85px
		img
			height: 100%
			width: auto
		@media screen and (min-width: $screen-sm-min)
			padding-left: 0
	.navbar-nav > li > a
		padding-top: 30px
		padding-bottom: 30px
	.navbar-toggle
		border: none
		text-align: center
		margin-top: 10px
		margin-right: 0
		background: transparent !important
		.icon-bar
			width: 30px
			height: 3px
			background-color: $gray-darker
			margin: 6px auto
			&:last-of-type
				margin-bottom: 0
		small
			font-size: .6em
			letter-spacing: .1em
			display: block
			color: $gray-darker
	.navbar-collapse
		box-shadow: none
		background-color: rgba(white, .95)
		height: calc(100vh - 85px)
		max-height: none
		@media screen and (min-width: $screen-sm-min)
			height: auto
		.navbar-nav
			p
				padding: 1em
			section
				padding: 1em
				h4
					color: $gray-darker
					padding: .15em 0 .5em 20px
					font-weight: 700
					position: relative
					font-family: "Crimson Text", serif
					font-size: 1.5em
					&:after
						content: ''
						display: block
						position: absolute
						left: 0
						top: 0
						width: 10px
						height: 24px
						background: transparent url("../img/red-ribbon.svg") left bottom no-repeat
						background-size: contain
				ul
					@extend .list-unstyled
					li
						font-family: "Open Sans", sans-serif
						padding: .3em 0
						&:before
							+font-awesome('\f0da')
							margin-right: .5em
						a
							transition: all 150ms ease-in-out
							color: $brand-primary
							text-decoration: none
							&:hover
								color: $gray-darker
				.btn-facebook
					font-family: 'Open Sans', sans-serif
			@media screen and (min-width: $screen-sm-min)
				display: flex
				flex-direction: row
				flex-wrap: wrap
				section
					width: 50%
			@media screen and (min-width: $screen-md-min)
				section
					width: calc(100% * 1/3)
			@media screen and (min-width: $screen-lg-min)
				section
					width: 25%

//==================================================
//                    HEADER
//==================================================

//header
//	margin: 85px 0 0
//	padding: 0
//	height: calc(100vh - 85px)
//	overflow: hidden
//	background: transparent url("../img/header-xs.jpg") center center no-repeat
//	background-size: cover
//	display: flex
//	align-items: center
//	position: relative
//	.header-content
//		text-align: center
//		.logo-seal
//			width: 300px
//			max-width: 60%
//	h1
//		font-family: "Crimson Text", serif
//		color: white
//		font-weight: 700
//	@media screen and (min-width: $screen-sm-min)
//		background-image: url("../img/header-sm.jpg")
//		height: calc(80vh - 85px)
//		.header-content
//			display: flex
//			align-items: center
//			justify-content: center
//			.logo-seal
//				max-width: 30%
//			h1
//				margin-left: 30px
//				justify-content: center
//	@media screen and (min-width: $screen-md-min)
//		background-image: url("../img/header-md.jpg")
//	@media screen and (min-width: $screen-lg-min)
//		background-image: url("../img/header-lg.jpg")

header
	margin: 85px 0 0
	padding: 0
	height: calc(100vh - 85px)
	overflow: hidden
	position: relative
	.swiper-container
		height: 100%
	.swiper-slide
		background-size: cover
		display: flex
		align-items: center
		.header-content
			position: relative
			text-align: center
			.logo-seal
				width: 300px
				max-width: 60%
				margin-top: -110vh
	h1
		font-family: "Crimson Text", serif
		color: white
		font-weight: 700
	@media screen and (min-width: $screen-sm-min)
		height: calc(80vh - 85px)
		.swiper-slide
			.header-content
				display: flex
				align-items: center
				justify-content: center
				.logo-seal
					max-width: 30%
				h1
					margin-left: 30px
					justify-content: center

#header-inner-page
	height: calc((100vh - 85px) / 2)
	display: flex
	align-items: center

	.container
		//offset for subheader
		margin-bottom: calc(.8em + 1rem + 1.2em)

		h2
			//text outline
			text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5)

			//make this look like h1
			font-family: "Crimson Text", serif
			color: white
			font-weight: 700

			//remove ribbon
			margin-left: 0
			&:before
				display: none

	.global-subheader
		width: 100%

	.breadcrumb
		margin-bottom: 0
		background-color: transparent

		a
			color: #fff

//formerly .announcements,
//repurposed for both announcements and breadcrumb
.global-subheader
	position: fixed
	bottom: 0
	left: 0
	width: 100vw
	color: white
	background-color: rgba(black, .7)
	padding: .8em 1em 1em
	font-size: 1.2em
	z-index: 999
	span
		font-style: italic
		font-weight: 700
		padding: 0 1.5em
		position: relative
		display: inline-block
		line-height: 1.3em
		&:before
			+font-awesome('\f0f3')
			position: absolute
			left: 0
			opacity: .5
	.close
		position: absolute
		top: .5em
		right: .5em
		color: $brand-primary
		opacity: 1
		font-weight: 400
		text-shadow: none
	@media screen and (min-width: $screen-sm-min)
		position: absolute
		font-size: 1.4em

.announcements
	text-align: center

//==================================================
//                     BODY
//==================================================

body
	background-color: #f2f2f4
	.page
		background: transparent url("../img/background-pattern.png") center 40px repeat-x
		background-size: 120px auto
		> .container
			background-color: white
			box-shadow: 0 0 3px rgba(black, .1)
			padding: 0

	main, aside
		display: inline-block
		vertical-align: top
	main
		padding: 30px
		width: 100%
		section
			margin-bottom: 60px
		@media screen and (min-width: $screen-sm-min)
			width: 100% * 2/3
			padding-bottom: 60px

	//any main not on the home page
	main.content-inner-page
		h2
			//remove ribbon
			margin-left: 0
			&:before
				display: none
		figure
			margin-top: $line-height-computed
			margin-bottom: $line-height-computed
			font-style: italic
			text-align: center
			img
				max-width: 100%
				height: auto
				max-height: 48vh

	aside
		padding: 30px 30px 100px
		width: 100%
		.btn-facebook
			width: 100%
			margin-top: 1em
		@media screen and (min-width: $screen-sm-min)
			padding-left: 0
			width: 100% * 1/3

		//section nav list
		ul
			@extend .list-unstyled
			li
				font-family: "Open Sans", sans-serif
				padding: .3em 0
				&:before
					+font-awesome('\f0da')
					margin-right: .5em
				a
					transition: all 150ms ease-in-out
					color: $brand-primary
					text-decoration: none
					&:hover
						color: $gray-darker
						margin-left: -5px
						text-decoration: none
			li.active a, li.active span
				color: $gray-darker
				margin-left: -5px
				text-decoration: none
				font-weight: 700


//==================================================
//                    FOOTER
//==================================================

footer
	position: relative
	padding: 100px 0
	text-align: center
	font-size: .9em
	&:before
		content: ''
		display: block
		position: absolute
		width: 120px
		height: 120px
		background: transparent url("../img/logo-seal-bw.png") center center no-repeat
		background-size: contain
		top: -60px
		left: 50%
		margin-left: -60px
	address
		margin-bottom: 1em
		span
			display: block
			margin: .5em 1em
			&:before
				display: inline-block
				font-family: 'FontAwesome', sans-serif
				margin-right: .6em
			&.postal:before
				content: '\f041'
			&.phone:before
				content: '\f095'
			&.fax:before
				content: '\f1ac'
			@media screen and (min-width: $screen-sm-min)
				display: inline-block
				+ span
					padding-left: 1em
					margin-left: 0
					border-left: 1px solid $gray-darker
	.copyright
		margin-top: 1em


//==================================================
//                    PRINT
//==================================================


