/*
 * Typography
 */


//==================================================
//                     IMPORTS
//==================================================

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Crimson+Text:400,700')
@import "variables"


//==================================================
//                     GLOBAL
//==================================================

*
	-webkit-font-smoothing: antialiased
	&::selection
		background-color: rgba($brand-primary, .3)

body
	font-size: 15px
	font-family: 'Open Sans', sans-serif
	line-height: 1.4em

p
	line-height: 1.4em

//==================================================
//                    HEADINGS
//==================================================

h1, .h1
	font-size: 3em
	@media screen and (min-width: $screen-md-min)
		font-size: 3.5em
	@media screen and (min-width: $screen-lg-min)
		font-size: 4em

h2, .small-ribbon
	font-family: 'Crimson Text', serif
	font-weight: 700
	position: relative
	font-size: 2em
	margin-left: 25px
	line-height: 1em
	&:before
		content: ''
		display: inline-block
		vertical-align: top
		width: 15px
		height: 36px
		margin-left: -25px
		margin-right: 10px
		background: transparent url("../img/red-ribbon.svg") 0 0 no-repeat
		background-size: contain
	@media screen and (min-width: $screen-sm-min)
		font-size: 2.5em

aside
	h2
		margin-bottom: 45px
	h2:not(:first-of-type)
		margin-top: 60px

h3.small-ribbon
	font-size: 2em
	&::before
		height: 24px

h3:not(.small-ribbon), h4.h3
	background-color: #b2b3bc

	//keep the width of this limited to its content
	display: inline-block

	color: white
	padding: .2em .4em .3em 30px
	font-style: italic
	margin-left: -30px
	position: relative
	margin-bottom: 30px
	&:after
		content: ''
		position: absolute
		top: 0
		right: -20px
		height: 100%
		width: 20px
		background: transparent url("../img/gray-ribbon.svg") right 0 no-repeat
		background-size: cover

//==================================================
//                   OTHER TAGS
//==================================================

