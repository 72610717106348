/*!
 * Components
 */


//==================================================
//                     IMPORTS
//==================================================

@import "variables"
@import "mixins"

//==================================================
//                     ALERTS
//==================================================



//==================================================
//                     BADGES
//==================================================



//==================================================
//                     BUTTONS
//==================================================

.btn
	border-radius: 0
	text-transform: uppercase
	font-weight: 700
	padding: .5em 1.5em
	transition: all 150ms ease-in-out
	&:before
		margin-right: .5em
	&:after
		margin-left: .5em

.btn-more
	&:after
		+font-awesome('\f105')

.btn-primary
	border: none
	&:hover
		background-color: lighten($brand-primary, 5%)

.btn-facebook
	color: $facebook
	border: 2px solid $facebook
	border-radius: 0
	font-weight: 700
	text-transform: uppercase
	&:before
		+font-awesome('\f082')
	&:hover
		color: $facebook
		background-color: rgba($facebook, .1)
	&.btn-facebook-solid
		background-color: $facebook
		color: white
		&:hover
			background-color: rgba($facebook, .9)

//==================================================
//                     CALENDAR
//==================================================

.calendar-buttons
	display: flex
	flex-wrap: wrap
	a:first-of-type
		margin-right: 0.5rem
		padding-right: 0.5rem
		border-right: 1px solid #000
	a:nth-of-type(2)
		margin-right: auto
	a:link, a:visited
		color: $brand-warning

.table-list
	background-color: #fff
	border: 2px solid #dddddd
	margin-bottom: 20px
	width: 100%

	th
		background-color: #eee
		padding: 18px 0
		@media (min-width: 325px)
			padding: 18px 10px

	td
		text-align: center
		background-color: #f5f5f5
		@media (min-width: 325px)
			padding: 13px 10px

	tr:hover td
		background-color: #f9f9f9

#calendar-table
	max-width: 100%
	vertical-align: baseline
	color: #727272
	th, td
		text-align: center
		border: 2px solid #fff
	th
		width: 14%
		font-size: 1.2rem

	th:first-of-type, th:last-of-type
		width: 15%
	td
		font-weight: normal
		height: 70px

		a:link, a:visited
			color: #727272

		.schedule-day
			display: none

	@media (min-width: #{$screen-md-min})
		td
			font-size: 2.6rem
			&.calendar-event div
				margin-top: 10px
			.schedule-day
				display: block
				font-size: 1rem
				cursor: pointer

		td > a:first-of-type
			pointer-events: none

	td.not-month
		background-color: #fff
		color: #ccc

	td.calendar-event
		font-weight: bold
		border: 1px solid #DADADA
		background-color: #E6E6E6

		div a
			font-weight: normal
			font-size: 1.56rem
			@media (max-width: #{$screen-sm-max})
				display: none

			&:link, &:visited
				color: $brand-warning
			&:hover, &:active
				color: darken($brand-warning, 15%)

//Now it's just the day that is orange when there is
//an event
@media (max-width: #{$screen-sm-max})
	#calendar-table td.calendar-event a
		&:link, &:visited
			color: $brand-warning
		&:hover, &:active
			color: darken($brand-warning, 15%)

//==================================================
//                      FORMS
//==================================================



//==================================================
//                      MEDIA
//==================================================



//==================================================
//                     PANELS
//==================================================



//==================================================
//                   PAGINATION
//==================================================



//==================================================
//                    POPOVERS
//==================================================

section.day-details
	position: relative
	background: #FFF
	padding: 20px 0
	margin-bottom: 0

	@media (min-width: #{$screen-md-min})
		margin: 20px auto
		max-width: 500px
		padding: 0 20px 20px
		&.no-events
			display: none

	//fix margins
	h3 + p
		margin-top: -20px
		margin-bottom: 30px

	h4 + time
		display: block

	&.selected
		@media (max-width: #{$screen-sm-max})
			display: block !important

	//back to calendar button
	[href="#calendar-table"]
		@media (min-width: #{$screen-md-min})
			display: none
		@extend .btn-primary
		float: right
		margin-right: $panel-body-padding
		font-size: 4rem
		width: 5rem
		height: 5rem
		border-radius: 20px
		transition: all 150ms ease-in-out
		display: flex
		justify-content: center
		align-items: center
		span
			display: block
			height: 1px
			width: 1px
			overflow: hidden
			color: transparent

//==================================================
//                     TABLES
//==================================================



//==================================================
//                      TABS
//==================================================



//==================================================
//                    TOOLTIPS
//==================================================




//==================================================
//                     WELLS
//==================================================







